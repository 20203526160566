<template>
  <div class="topbar" :class="'topbar-' + $route.meta.navbarColor">
    <div class="lang-bar">
      <div class="container d-flex justify-content-between align-items-center">
        <p class="m-0">Live, learn and speak!</p>
        <div id="lang-selector">
          <a
            href="#"
            @click.prevent="toggleLangSelect"
            @mouseleave="onLangMouseLeave"
          >
            <span>{{ $route.params.lang.toUpperCase() }} &nbsp;</span>
            <i class="fa-solid fa-angle-down"></i>
          </a>

          <ul :class="{ opened: showLangSel }">
            <li>
              <router-link
                @click="onClickLink"
                :to="{ name: $route.name, params: { lang: 'en' } }"
              >
                EN - English
              </router-link>
            </li>
            <li>
              <router-link
                @click="onClickLink"
                :to="{ name: $route.name, params: { lang: 'es' } }"
              >
                ES - Español
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="navbar">
      <div class="container d-flex justify-content-between align-items-stretch">
        <div class="brand">
          <router-link @click="onClickLink" to="/">
            <img
              :src="
                $route.name === 'madrid' // ? '/imgs/logo_w_cervantes.png'
                  ? '/svgs/logo.svg'
                  : '/svgs/logo.svg'
              "
            />
          </router-link>
        </div>
        <nav :class="{ active: navbarActive }">
          <ul>
            <li :class="{ active: liActive('/campus') }">
              <ul id="submenu" :class="{ hidden: showSubmenu !== 'campus' }">
                <li>
                  <i class="fa-solid fa-angle-right"></i>
                  <router-link @click="onClickLink" to="/campus/barcelona">
                    Barcelona
                  </router-link>
                </li>
                <li>
                  <i class="fa-solid fa-angle-right"></i>
                  <router-link @click="onClickLink" to="/campus/madrid">
                    Madrid
                  </router-link>
                </li>
              </ul>
              <a
                href="#"
                @click.prevent="toggleSubmenu('campus')"
                @mouseleave="onSubmenuMouseLeave"
              >
                {{ $lang("menus.campus", true) }}
                <i class="fa-solid fa-angle-down"></i>
              </a>
            </li>

            <li :class="{ active: liActive('/courses') }">
              <ul id="submenu" :class="{ hidden: showSubmenu !== 'courses' }">
                <li>
                  <i class="fa-solid fa-angle-right"></i>
                  <router-link @click="onClickLink" to="/courses/spanish">
                    {{ $lang("menus.spanish_courses", true) }}
                  </router-link>
                </li>
                <li>
                  <i class="fa-solid fa-angle-right"></i>
                  <router-link @click="onClickLink" to="/courses/english">
                    {{ $lang("menus.english_courses", true) }}
                  </router-link>
                </li>
              </ul>
              <a
                href="#"
                @click.prevent="toggleSubmenu('courses')"
                @mouseleave="onSubmenuMouseLeave"
              >
                {{ $lang("menus.courses", true) }}
                <i class="fa-solid fa-angle-down"></i>
              </a>
            </li>
            <li :class="{ active: liActive('/placement-test') }">
              <router-link @click="onClickLink" to="/placement-test">
                {{ $lang("menus.placement_test", true) }}
              </router-link>
            </li>
            <li :class="{ active: liActive('/price-list') }">
              <router-link @click="onClickLink" to="/price-list">
                {{ $lang("menus.price_list", true) }}
              </router-link>
            </li>
            <li :class="{ active: liActive('/student-life') }">
              <ul
                id="submenu"
                class="grid"
                :class="{ hidden: showSubmenu !== 'student-life' }"
              >
                <li>
                  <i class="fa-solid fa-angle-right"></i>
                  <router-link
                    @click="onClickLink"
                    to="/student-life/accommodation"
                  >
                    {{ $lang("menus.accommodation", true) }}
                  </router-link>
                </li>
                <li>
                  <i class="fa-solid fa-angle-right"></i>
                  <router-link @click="onClickLink" to="/student-life/exams">
                    {{ $lang("menus.exams", true) }}
                  </router-link>
                </li>
                <li>
                  <i class="fa-solid fa-angle-right"></i>
                  <router-link @click="onClickLink" to="/student-life/visa">
                    {{ $lang("menus.visa", true) }}
                  </router-link>
                </li>
                <li>
                  <i class="fa-solid fa-angle-right"></i>
                  <router-link
                    @click="onClickLink"
                    to="/student-life/extra-activities"
                  >
                    {{ $lang("menus.extra_activities", true) }}
                  </router-link>
                </li>
                <li>
                  <i class="fa-solid fa-angle-right"></i>
                  <router-link
                    @click="onClickLink"
                    to="/student-life/health-insurance"
                  >
                    {{ $lang("menus.health_insurance", true) }}
                  </router-link>
                </li>
              </ul>
              <a
                href="#"
                @click.prevent="toggleSubmenu('student-life')"
                @mouseleave="onSubmenuMouseLeave"
              >
                {{ $lang("menus.student_life", true) }}
                <i class="fa-solid fa-angle-down"></i>
              </a>
            </li>
            <li :class="{ active: liActive('/contact-us') }">
              <router-link @click="onClickLink" to="/contact-us">
                {{ $lang("menus.contact_us", true) }}
              </router-link>
            </li>
          </ul>
          <button class="close" @click="toggleNavbar(false)">
            <i class="fa-solid fa-xmark"></i>
          </button>
        </nav>
        <button class="collapse" @click="toggleNavbar(true)">
          <i class="fa-solid fa-bars"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import { useRoute } from "vue-router";

export default {
  setup() {
    let navbarActive = ref(false);
    let showSubmenu = ref(null);
    let showLangSel = ref(false);

    const clickOutsideMenu = (event) => {
      const submenu = document.querySelector("#submenu");
      var isClickInsideElement = submenu.contains(event.target);
      if (!isClickInsideElement && showSubmenu.value) {
        toggleSubmenu(null);

        document.removeEventListener("click", clickOutsideMenu);
      }
    };

    const toggleNavbar = (active) => {
      navbarActive.value = active;
    };
    const toggleSubmenu = (value) => {
      showSubmenu.value = showSubmenu.value === value ? null : value;
    };
    const onSubmenuMouseLeave = () => {
      if (showSubmenu.value) {
        document.addEventListener("click", clickOutsideMenu);
      }
    };
    const onClickLink = () => {
      if (navbarActive.value) navbarActive.value = false;
      if (showSubmenu.value) showSubmenu.value = null;
      if (showLangSel.value) showLangSel.value = false;

      document.removeEventListener("click", clickOutsideMenu);
      document.removeEventListener("click", clickOutsideLang);
    };

    const toggleLangSelect = () => {
      showLangSel.value = !showLangSel.value;
    };
    const clickOutsideLang = (event) => {
      const langMenu = document.querySelector("#lang-selector ul");
      var isClickInsideElement = langMenu.contains(event.target);

      if (!isClickInsideElement && showLangSel.value) {
        toggleLangSelect();

        document.removeEventListener("click", clickOutsideLang);
      }
    };
    const onLangMouseLeave = () => {
      if (showLangSel.value) {
        document.addEventListener("click", clickOutsideLang);
      }
    };

    const liActive = (route) => {
      const $route = useRoute();

      return $route.path.indexOf(route) > -1;
    };

    return {
      navbarActive,
      toggleNavbar,
      showSubmenu,
      toggleSubmenu,
      onSubmenuMouseLeave,
      onClickLink,
      showLangSel,
      toggleLangSelect,
      onLangMouseLeave,
      liActive,
    };
  },
};
</script>
