<template>
  <footer class="decoration-3">
    <div class="container" v-if="!$route.meta.footer_no_form">
      <div class="details">
        <div class="brand pt-4">
          <img src="/svgs/logo.svg" alt="" />
        </div>
        <div class="d-flex flex-equal">
          <div class="campus-details">
            <h5 class="campus-title text-secondary">Barcelona Campus</h5>
            <p class="text-medium">
              Carrer de Guillem Tell, 27, 08006 Barcelona, España
            </p>
            <p class="mb-0">
              <i class="mr-2 fa-solid fa-phone text-secondary"></i>
              <a href="tel:34935151174">+34 93 515 11 74</a>
            </p>
            <p class="mb-0">
              <i class="mr-2 fa-brands fa-whatsapp text-secondary"></i>
              <a target="_blank" href="http://wa.me/34634131769">
                +34 63 413 17 69
              </a>
            </p>
            <p class="mb-0">
              <i class="mr-2 fa-regular fa-envelope text-secondary"></i>
              <a href="mailto:info@nlcollege.es">info@nlcollege.es</a>
            </p>
          </div>
          <div class="campus-details">
            <h5 class="campus-title text-tertiary">Madrid Campus</h5>
            <p class="text-medium">
              Plaza Puerta del Sol 11, Segunda Planta, 28013 Madrid, España
            </p>
            <p class="mb-0">
              <i class="mr-2 fa-solid fa-phone text-tertiary"></i>
              <a href="tel:34917376633">+34 91 737 66 33</a>
            </p>
            <p class="mb-0">
              <i class="mr-2 fa-brands fa-whatsapp text-tertiary"></i>
              <a target="_blank" href="http://wa.me/34634131769">
                +34 63 413 17 69
              </a>
            </p>
            <p class="mb-0">
              <i class="mr-2 fa-regular fa-envelope text-tertiary"></i>
              <a href="mailto:info@nlcollege.es">info@nlcollege.es</a>
            </p>
          </div>
        </div>
        <div class="privacy-policy">
          <p>
            <router-link to="/privacy-policy"
              >> Política de privacidad</router-link
            >
          </p>
        </div>
        <div class="social-buttons no-mobile">
          <a href="https://www.instagram.com/nlcollege.spain" target="_blank" class="btn btn-rounded btn-primary">
            <i class="fa-brands fa-instagram"></i>
          </a>
          <a href="https://www.youtube.com/c/NLCollegeSpain" target="_blank" class="btn btn-rounded btn-primary">
            <i class="fa-brands fa-youtube"></i>
          </a>
          <a href="https://www.linkedin.com/company/nlcollege-spain" target="_blank" class="btn btn-rounded btn-primary">
            <i class="fa-brands fa-linkedin"></i>
          </a>
        </div>
        <div class="copyright no-mobile">
          <small>Copyright © NL College 2022</small>
        </div>
      </div>
      <div class="mobile mt-4">
        <div class="social-buttons">
          <a href="https://www.instagram.com/nlcollege.spain" target="_blank" class="btn btn-rounded btn-primary">
            <i class="fa-brands fa-instagram"></i>
          </a>
          <a href="https://www.youtube.com/c/NLCollegeSpain" target="_blank" class="btn btn-rounded btn-primary">
            <i class="fa-brands fa-youtube"></i>
          </a>
          <a href="https://www.linkedin.com/company/nlcollege-spain" target="_blank" class="btn btn-rounded btn-primary">
            <i class="fa-brands fa-linkedin"></i>
          </a>
        </div>
        <div class="copyright">
          <small>Copyright © NL College 2022</small>
        </div>
      </div>
    </div>
    <div class="container" v-else>
      <div class="details flex-row-desktop mt-3">
        <div class="brand mb-3 mb-md-0">
          <img src="/svgs/logo.svg" alt="" />
        </div>
        <div class="copyright">
          <small>Copyright © NL College 2022</small>
        </div>
      </div>
    </div>
  </footer>
</template>
